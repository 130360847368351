
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-0.jpg?alt=media&token=8bede7d1-6ff5-4e1a-ab13-52ce11799be4'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-1.jpg?alt=media&token=e2d7f91f-ced7-479d-9854-2c7d5e6d383a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-2.jpg?alt=media&token=5dfbc9c0-d630-414b-b8ba-1352a7740b32'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-3.jpg?alt=media&token=23c5955c-0e55-4431-8a4a-92b20663d3d0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-4.jpg?alt=media&token=5c665128-6d85-4bea-b7a0-85185badc310'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-5.jpg?alt=media&token=5a25a53d-ff10-4e36-98b9-f64ad64bde35'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-6.jpg?alt=media&token=c234b78a-a6b8-42fb-9fe5-f4aedfb3344a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-7.jpg?alt=media&token=5aeaace1-61dc-4e80-9ba4-977863b78f30'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-8.jpg?alt=media&token=268d3426-2808-4830-a576-9ff452fb078c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-9.jpg?alt=media&token=498b9188-0b35-42db-91ad-39f8b9099a47'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-10.jpg?alt=media&token=4cb471ff-00e1-4343-9d78-e8632d7afaf1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-11.jpg?alt=media&token=cd9b423e-2f68-449d-9699-85716fbad4a5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-12.jpg?alt=media&token=d085bae8-f9ba-4fb0-8290-98f093ca7ba6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-13.jpg?alt=media&token=4cad79d1-5761-42df-b779-0aae796b27e6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-14.jpg?alt=media&token=2fd9e8ea-b5fc-4643-9e7e-b005e912d832'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-15.jpg?alt=media&token=97e1914e-6abd-4293-b9d7-08693aeb268f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-16.jpg?alt=media&token=ab70a9c6-1a20-4fd2-a376-5fd7a587abda'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-17.jpg?alt=media&token=a90c6239-e8c1-4eaa-b947-ad7783d895a6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-18.jpg?alt=media&token=31221377-4536-49c2-a870-36b7e52e4a71'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-19.jpg?alt=media&token=968b887c-6fc5-47f0-9f81-d448b17e2a36'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-20.jpg?alt=media&token=594b8887-8fea-40ff-9c5b-96c7dd67b82c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-21.jpg?alt=media&token=d64555e7-c0e2-49f8-a981-cbd4f34c6f4d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-22.jpg?alt=media&token=faaae8f1-45ea-4aee-9be9-de3449d18de1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-23.jpg?alt=media&token=c54c046f-d259-4952-a01a-d3eaa9525c2b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-24.jpg?alt=media&token=b47b2bfa-11d1-4250-a16f-f733d168754d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2F6fb2001179dcce624add69c62bf069d9-25.jpg?alt=media&token=c79132e6-5bf7-48ba-9926-804110ec8997'} alt="boohoo" className="img-responsive"/>
          </div>
         

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-diversity.appspot.com/o/Special%20Course%2FSpanish%2FDIVU1ES%2FDIVU1ESMAT.pdf?alt=media&token=8fa83968-27be-47ed-bfcc-495d7be3ab08' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
